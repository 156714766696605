import approve from './approve';
import get from './get';
import reject from './reject';
import relations from './relations';
import update from './update';

export default {
  approve,
  get,
  reject,
  relations,
  update,
};
