import advanced from './advanced';
import affiliate from './affiliate';
import list from './list';
import view from './view';

export default {
  advanced,
  affiliate,
  list,
  view,
};
