import affiliates from './affiliates';
import announcements from './announcements';
import billing from './billing';
import campaigns from './campaigns';
import common from './common';
import creatives from './creatives';
import dashboard from './dashboard';
import offers from './offers';
import payments from './payments';
import payouts from './payouts';
import profile from './profile';
import settings from './settings';

export default {
  en: {
    affiliates,
    announcements,
    billing,
    campaigns,
    common,
    creatives,
    dashboard,
    offers,
    payments,
    payouts,
    profile,
    settings,
  },
};
