<template>
  <div
    class="image-item"
    :class="{ 'image-item-hover': hover }"
    @mouseover="hover = true"
    @mouseout="hover = false"
    data-testid="elem_u_000738"
  >
    <div
      v-if="loading"
      class="image-item__loader-wrapper"
    >
      <v-progress-circular
        :size="50"
        color="#F4DB64"
        indeterminate
        data-testid="elem_u_003210"
      />
    </div>

    <v-tooltip
      bottom
      offset-overflow
      max-width="300"
      :open-delay="300"
      z-index="100"
      data-testid="elem_u_000739"
    >
      <template #activator="{ on, attrs }">
        <div
          class="upload-item-image"
          :class="{
            'upload-item-image__rounded': rounded,
            'upload-item-image__small': small,
            'upload-item-image__rectangular': rectangular,
          }"
          v-bind="attrs"
          v-on="on"
          data-testid="elem_u_000740"
        >
          <embed
            v-if="type.split('/')[1] === 'pdf'"
            type="application/pdf"
            :src="url"
            :width="small ? 70 : rectangular ? 230 : 115"
            :height="small ? 70 : 115"
          />
          <FullImageDialog
            v-else-if="type.split('/')[0] === 'video'"
            #default="{ on }"
            :src="url"
            :alt="alt"
            type="video"
          >
            <div>
              <video
                class="video"
                v-on="on"
                :src="url"
              ></video>
            </div>
          </FullImageDialog>

          <FullImageDialog
            v-else
            #default="{ on }"
            :src="url"
            :alt="alt"
            data-testid="elem_u_000741"
          >
            <div
              v-if="imgLoading"
              class="image-item__loader-wrapper"
            >
              <v-progress-circular
                :size="50"
                color="#F4DB64"
                indeterminate
                data-testid="elem_u_003210"
              />
            </div>

            <v-img
              v-on="on"
              :src="url"
              :alt="alt"
              :width="small ? 70 : rectangular ? 230 : 115"
              :height="small ? 70 : 115"
              data-testid="elem_u_000742"
              @load="imgLoading = false"
            />
          </FullImageDialog>
        </div>
      </template>
      <span data-testid="elem_u_000743">{{ alt || url }}</span>
    </v-tooltip>

    <ImageItemControls
      v-if="controls"
      :uploadable="!readonly"
      :clearable="clearable"
      :accept="accept"
      :hint="hint"
      :size="size"
      class="image-item-controls-block"
      :class="{ 'image-item-controls-block__small': small }"
      @upload="(image) => $emit('upload', image)"
      @clear="$emit('clear')"
      data-testid="elem_u_000744"
    />
  </div>
</template>

<script>
import FullImageDialog from '@/components/common/FullImageDialog';
import ImageItemControls from './ImageItemControls';

export default {
  components: {
    FullImageDialog,
    ImageItemControls,
  },

  props: {
    url: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 2 * 1024 * 1024, // 2Mb
    },
    hint: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    rectangular: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
      imgLoading: true,
    };
  },
};
</script>

<style lang="scss">
.image-item__loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: initial;
}

.video {
  width: 100%;
  height: 100%;
}

.image-item {
  cursor: pointer;
  position: relative;
}

.upload-item-image {
  border-radius: 8px;
  width: 115px;
  height: 115px;
  overflow: hidden;
  transform: scale(1, 1);
  transition: transform 300ms;
  &:hover {
    transform: scale(1.1, 1.1);
    transition: transform 300ms;
  }
  &__rounded {
    border-radius: 50%;
  }
  &__small {
    width: 70px;
    height: 70px;
  }
  &__rectangular {
    width: 230px;
    height: 115px;
  }
}

.image-item-controls-block {
  position: absolute;
  bottom: -20px;
  left: 20px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 300ms,
    visibility 300ms,
    bottom 300ms;
  &__small {
    left: -10px;
  }
}

.image-item-hover {
  .image-item-controls-block {
    opacity: 1;
    visibility: visible;
    bottom: -50px;
    transition:
      opacity 300ms,
      visibility 300ms,
      bottom 300ms;
  }
}
</style>
