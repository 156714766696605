import { WIDGET_EMPTY_VALUE } from './types';

export default {
  methods: {
    async save({ isSingle = false } = {}) {
      if (!this.validate()) {
        return false;
      }

      this.resetAllErrors();

      const saveValue = this.widgets.reduce(
        (saveVal, widget) => {
          // Если есть поле other, то сохраняем его и otherFieldForSave
          if (
            widget.hasOwnProperty('otherFieldForSave') &&
            this.value.hasOwnProperty(widget.otherFieldForSave) &&
            widget.otherFieldForSave !== ''
          ) {
            return {
              ...saveVal,
              ...(widget.save && this.value[widget.name] !== WIDGET_EMPTY_VALUE
                ? { [widget.name]: this.value[widget.name] }
                : {}),
              ...(widget.save && this.value[widget.otherFieldForSave] !== WIDGET_EMPTY_VALUE
                ? { [widget.otherFieldForSave]: this.value[widget.otherFieldForSave] }
                : {}),
            };
          } else if (
            (widget.type === this.$options.FORM_FIELD_TYPES.select ||
              widget.type === this.$options.FORM_FIELD_TYPES.relationSelect ||
              widget.type === this.$options.FORM_FIELD_TYPES.autocomplete ||
              widget.type === this.$options.FORM_FIELD_TYPES.relationAutocomplete) &&
            widget.multiple &&
            !this.value[widget.name]
          ) {
            return {
              ...saveVal,
              ...(widget.save && this.value[widget.name] !== WIDGET_EMPTY_VALUE
                ? { [widget.name]: null }
                : {}),
            };
          } else if (widget.type === this.$options.FORM_FIELD_TYPES.checkBox) {
            return {
              ...saveVal,
              ...(widget.save && this.value[widget.name] !== WIDGET_EMPTY_VALUE
                ? { [widget.name]: this.value[widget.name] ? true : false }
                : { [widget.name]: false }),
            };
          } else {
            return {
              ...saveVal,
              ...(widget.save && this.value[widget.name] !== WIDGET_EMPTY_VALUE
                ? { [widget.name]: this.value[widget.name] }
                : {}),
            };
          }
        },
        { id: !this.isCreated ? this.value.id : undefined },
      );

      if (isSingle) {
        this.isCreated = false;
      }

      const success = this.isCreated
        ? await this.provider.create(saveValue, this.permissions)
        : await this.provider.update(this.deleteImageObjects(saveValue), {
            noServerSave: this.noServerSave,
          });

      if (!success) {
        if (Object.keys(this.provider.errors_of_fields).length > 0) {
          this.errors = this.provider.errors_of_fields;
        } else if (this.provider.error_message) {
          this.$alert.notify(this.provider.error_message, 'error');
        } else {
          this.$alert.notify('Save failure!', 'error');
        }

        return false;
      }

      if (!this.noSaveMessage) {
        const successfully_message = this.isCreated
          ? this.messages
            ? this.$t(`${this.messages}.alert.created_successfully`)
            : 'Created successfully'
          : this.messages
            ? this.$t(`${this.messages}.alert.updated_successfully`)
            : 'Updated successfully';

        this.$alert.notify(successfully_message);
      }

      if (!this.noResetAfterSave) {
        this.resetValue();
      }

      if (!isSingle) {
        this.isCreated = true;
      }

      return true;
    },

    setValue(item, { isCreate = false } = {}) {
      if (!item) {
        return;
      }

      const fields = [...this.fields, { name: 'id' }];

      this.value = fields.reduce((obj, field) => {
        if (field.hasOwnProperty('otherId') && field.otherId !== '' && field.otherFieldForSave) {
          return {
            ...obj,
            [field.name]: item[field.name],
            [field.otherFieldForSave]: item[field.otherFieldForSave],
          };
        } else {
          return {
            ...obj,
            [field.name]: item[field.name],
          };
        }
      }, {});

      Object.keys(this.value).forEach((fieldName) => {
        if (this.getWidget(fieldName)) {
          this.onWidgetInput(fieldName, this.value[fieldName]);
        }
      });

      this.deleted_images = {};

      this.isCreated = isCreate;
    },

    setField(field, value) {
      this.value[field] = value;
    },

    resetValue() {
      this.value = this.fields.reduce((obj, field) => {
        if (field.hasOwnProperty('otherId') && field.otherId !== '' && field.otherFieldForSave) {
          return {
            ...obj,
            [field.name]: this.getWidgetDefaultValue(field),
            [field.otherFieldForSave]: '',
          };
        } else {
          return {
            ...obj,
            [field.name]: this.getWidgetDefaultValue(field),
          };
        }
      }, {});

      this.deleted_images = {};
      this.isCreated = true;
    },

    deleteImageObjects(value) {
      let modifyValue = { ...value };

      this.widgets.map((widget) => {
        if (
          (widget.type === this.$options.FORM_FIELD_TYPES.upload ||
            widget.type === this.$options.FORM_FIELD_TYPES.uploadImage) &&
          !widget.multiple
        ) {
          if (modifyValue[widget.name]?.uuid ?? false) {
            delete modifyValue[widget.name];
          }
        } else if (
          (widget.type === this.$options.FORM_FIELD_TYPES.upload ||
            widget.type === this.$options.FORM_FIELD_TYPES.uploadImage) &&
          widget.multiple
        ) {
          modifyValue[widget.name] = modifyValue[widget.name].filter(
            (image) => !image?.uuid ?? false,
          );

          if (modifyValue[widget.name].length === 0) {
            delete modifyValue[widget.name];
          }
        }
      });

      return {
        ...modifyValue,
        ...this.deleted_images,
      };
    },
  },
};
