import axios from 'axios';

export default async function (params) {
  return await axios
    .get(`/v1/user/profile`, {
      params,
    })
    .then(({ data: res }) => {
      res.data.billing_account_recipient_type = res.data.billing_account?.data?.recipient_type;

      return res;
    });
}
