<template>
  <v-tooltip
    bottom
    offset-overflow
    max-width="300"
    :open-delay="300"
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="profile-issues"
        data-testid="elem_u_000050"
      >
        <div
          class="profile-issues_content"
          data-testid="elem_u_000051"
        >
          <v-img
            height="24"
            width="24"
            src="@/assets/img/warn.png"
            data-testid="elem_u_000146"
          />
          <span data-testid="elem_u_000147">Not eligible for payouts</span>
        </div>
      </div>
    </template>
    <ul>
      <li
        v-for="issue in issues"
        :key="issue"
      >
        {{ issue }}
      </li>
    </ul>
  </v-tooltip>
</template>

<script>
const ISSUES_DATA = {
  billing_account_incomplete: 'The billing info is incomplete',
  legal_forms_not_submitted: 'Not all legal forms have been uploaded',
  legal_forms_not_accepted: 'Documents are not accepted',
  legal_forms_rejected: 'One of the legal forms has been rejected',
  legal_forms_not_accepted: 'One of the legal forms is not approved',
  legal_forms_expired: 'One of the legal forms has expired',
  user_details_missing: 'Profile is incomplete',
};

export default {
  name: 'ProfileIssues',

  computed: {
    issues() {
      const issuesObj = this.$store?.state?.user?.userData?.issues;
      let issuesArr = [];
      if (issuesObj) {
        Object.values(issuesObj).forEach((issuesBlock) => {
          issuesBlock.forEach((issue) => {
            issuesArr.push(ISSUES_DATA[issue] || issue);
          });
        });
      }

      return issuesArr;
    },
  },
};
</script>

<style lang="scss">
.main-navigation {
  .profile-issues {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 54px;
    background: var(--color-grey-280);
    border-radius: 15px;
    padding: 0 25px;

    .profile-issues_content {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;
      gap: 12px;
      height: 100%;
      min-width: 170px;

      color: var(--color-grey-900);
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
</style>
