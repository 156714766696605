export default {
  title: 'Landing Pages',
  notify_block: {
    text: 'Get your links to the best selling landing pages and send your traffic there. ',
  },
  filter_block: {
    title: 'Filter Landing Pages',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  form_edit_block: {
    button_create: 'create landing page',
    button_update: 'update landing page',
    button_clear: 'cancel update landing page',
    button_upload: 'Upload Image',
  },
  alert: {
    created_successfully: 'Landing page created successfully',
    updated_successfully: 'Landing page updated successfully',
    deleted_successfully: 'Landing page deleted successfully',
  },
  form_edit_dialog: {
    title_create: 'create new landing page',
    title_update: 'update landing page',
    button_create: 'create landing page',
    button_update: 'update',
    button_close: 'close',
  },
  data_table: {
    title: '',
    button_create: 'Create New Landing Page',
    menu_actions: {
      edit: 'Edit',
      delete: 'Delete',
    },
    delete_dialog: {
      title: 'Delete Landing Page',
      button_yes: 'delete',
      button_no: 'cancel',
    },
  },
  creative_block: {
    button_get_link: 'Get link',
    copy_dialog_link_header: 'Get Link',
  },
  filter_labels: {
    site: 'Site',
    language: 'Language',
    rating: 'Rating',
    format: 'Format',
  },
  form_labels: {
    name: 'Name',
    url: 'Destination URL',
    description: 'Description',
    language: 'Language',
    rating: 'Rating',
    image: 'Upload image',
  },
};
