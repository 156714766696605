export default {
  form_edit_dialog: {
    title_update: 'Edit Amount',
    button_update: 'save',
    button_cancel: 'cancel',
  },
  form_labels: {
    site: 'Affiliate',
    amount: 'Amount',
  },
  data_table: {
    menu_actions: {
      edit: 'edit',
      delete: 'delete',
    },
  },
};
