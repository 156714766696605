import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { hasPermission, isClient, checkTokenExpiration } from '@/services/helpers-services.js';
import getTokenByCode from '@/api/users/getTokenByCode';

Vue.use(VueRouter);

const routes = [
  // Home Page
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "home" */
        /* webpackPrefetch: true */
        '../views/home/Home.vue'
      ),
    meta: { layout: 'HomeLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },

  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('../views/home/TermsAndConditions.vue'),
    meta: { layout: 'TermsAndPrivacyLayout' },
  },

  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/home/PrivacyPolicy.vue'),
    meta: { layout: 'TermsAndPrivacyLayout' },
  },

  // App Pages
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/app/Dashboard/Dashboard.vue'),
    meta: { protected: true, permission: 'dashboard', layout: 'AppLayout' },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () =>
      import(/* webpackChunkName: "statistics" */ '../views/app/Statistics/Statistics.vue'),
    meta: { protected: true, permission: 'statistics', layout: 'AppLayout' },
  },
  {
    path: '/creatives',
    name: 'Creatives',
    component: () =>
      import(/* webpackChunkName: "creatives" */ '../views/app/Creatives/Creatives.vue'),
    meta: { protected: true, permission: 'creatives', layout: 'AppLayout' },
    props: true,
  },
  {
    path: '/payouts',
    name: 'Payouts',
    component: () => import(/* webpackChunkName: "payouts" */ '../views/app/Payouts/Payouts.vue'),
    meta: { protected: true, permission: 'payouts', layout: 'AppLayout' },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () =>
      import(/* webpackChunkName: "payments" */ '../views/app/Payments/Payments.vue'),
    meta: { protected: true, layout: 'AppLayout', role: 'client' },
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/app/Campaigns/Campaigns.vue'),
    meta: { protected: true, layout: 'AppLayout', role: 'client' },
  },
  {
    path: '/offers',
    name: 'Offers',
    component: () => import(/* webpackChunkName: "offers" */ '../views/app/Offers/Offers.vue'),
    meta: { protected: true, permission: 'offers', layout: 'AppLayout' },
  },
  {
    path: '/offers/:id',
    name: 'OfferView',
    component: () =>
      import(/* webpackChunkName: "offersAdvanced" */ '../views/app/Offers/View/Page.vue'),
    meta: { protected: true, permission: 'offers-edit', layout: 'AppLayout' },
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: () =>
      import(/* webpackChunkName: "affiliates" */ '../views/app/Affiliates/Affiliates.vue'),
    meta: { protected: true, permission: 'affiliates', layout: 'AppLayout', role: 'admin' },
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ '../views/app/Announcements/Announcements.vue'
      ),
    meta: { protected: true, permission: 'announcements', layout: 'AppLayout' },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/app/Settings/Page.vue'),
    meta: { protected: true, permission: 'settings', layout: 'AppLayout' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/app/Profile/Profile.vue'),
    meta: { protected: true, permission: '', layout: 'AppLayout' },
  },

  // Authentication
  {
    path: '/signin',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },
  {
    path: '/password-reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      } else if (!to.query.token) {
        next({
          name: 'Login',
        });
      }
      next();
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },
  {
    path: '/activate-account',
    name: 'ActivateAccount',
    component: () => import(/* webpackChunkName: "activate-account" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },
  {
    path: '/two-step-signin',
    name: 'TwoStepVerification',
    component: () => import(/* webpackChunkName: "activate-account" */ '../views/auth/Auth.vue'),
    meta: { layout: 'AuthLayout' },
    beforeEnter(to, from, next) {
      if (store.getters['auth/authenticated']) {
        next({
          name: 'Dashboard',
        });
      }
      next();
    },
  },

  // Error 404 page
  {
    path: '*',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "error404" */ '../views/Error404.vue'),
    meta: { layout: 'AuthLayout' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.lac || to.query.lbc) {
    const { status, data } = await getTokenByCode(to.query);

    if (status === 200) {
      store.commit('auth/login', {
        token_type: data.token_type,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      });
    } else {
      store.dispatch('alert/setAlert', { message: 'Code is invalid', type: 'error' });
    }

    return checkPermissions();
  }

  if (to.query.login_as) {
    store.commit('auth/login', {
      token_type: to.query.token_type,
      access_token: to.query.access_token,
      refresh_token: to.query.refresh_token,
    });

    return checkPermissions();
  }

  // Check if current route is protected
  const routeProtected = to.matched.some((record) => record.meta.protected);

  if (!routeProtected) return next();

  // And user is authenticated
  const userAuthenticated = store.getters['auth/authenticated'];

  if (!userAuthenticated) return next({ name: 'Home' });

  // And his token is refreshed
  if (checkTokenExpiration()) return checkPermissions();

  const refresh_token = localStorage.getItem('refreshToken') || null;

  if (!refresh_token || refresh_token === '-') {
    store.dispatch('auth/logout', true);

    Vue.prototype.$alert.notify("Time's up. You need to log in to your account again", 'error');

    return;
  }

  return store.dispatch('auth/getFreshToken', { refresh_token }).then(
    () => checkPermissions(),
    ({ message }) => {
      store.dispatch('auth/logout', true);

      Vue.prototype.$alert.notify(message, 'error');
    },
  );

  async function checkPermissions() {
    // Check if user has the necessary permissions to visit this route
    const isPermissionRequired = to.matched.some((record) => record.meta.permission);
    const isSpecificRoleRequired = to.matched.some((record) => record.meta.role);

    if (!isPermissionRequired && !isSpecificRoleRequired) {
      if (from.name === null) {
        await fetchUserData(from);
      }

      return next();
    }

    // In case user data exists
    if (store.state.user.userData) {
      await fetchUserData(from);

      return allowAccess();
    }

    // In case storage is empty and user permissions have not been requested yet
    // Get permissions first, then execute the previous step
    return store.dispatch('user/getUserData').then(
      () => allowAccess(),
      (error) => {
        Vue.prototype.$alert.notify(error.message, 'error');

        return next({
          name: 'Profile',
        });
      },
    );
  }

  function allowAccess() {
    if (store.state.user.userData.is_active === false) {
      Vue.prototype.$alert.notify('User is inactive', 'error');

      store.dispatch('auth/logout');
    }

    const isRequiredRole = to.meta.role ? (to.meta.role === 'client') === isClient() : true;
    const isRequiredPermission = to.meta.permission ? hasPermission(to.meta.permission) : true;

    return isRequiredRole && isRequiredPermission
      ? next()
      : next({
          name: 'Profile',
        });
  }
});

async function fetchUserData(from) {
  if (from.name === null) {
    await store.dispatch('user/getUserData').catch((err) => {
      Vue.prototype.$alert.notify(err.message, 'error');
    });
  }
}

export default router;
