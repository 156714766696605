<template>
  <component
    @click="onClick"
    :is="typeComponent"
    :class="[
      type,
      outlined ? 'outlined' : '',
      capitalize ? 'capitalize' : '',
      loading ? 'no-events' : '',
    ]"
    :style="cssVars"
    :disabled="disabled"
    :to="to"
    class="unzip-button"
    ref="Button"
    data-testid="elem_u_003209"
  >
    <v-progress-circular
      v-if="loading"
      size="14"
      width="2"
      indeterminate
      data-testid="elem_u_003210"
    />
    <template v-else>
      <v-icon
        v-if="prependIcon"
        size="18"
        data-testid="elem_u_003211"
      >
        {{ prependIcon }}
      </v-icon>
      {{ label }}
      <slot />
      <v-icon
        v-if="appendIcon"
        size="18"
        data-testid="elem_u_003212"
      >
        {{ appendIcon }}
      </v-icon>
    </template>
  </component>
</template>

<script>
const TYPES_BUTTONS = {
  primary: 'primary',
  secondary: 'secondary',
  gray: 'gray',
  text: 'text',
};

export default {
  TYPES_BUTTONS,

  props: {
    label: {
      type: String,
      default: '',
    },
    height: {
      type: Number | String,
      default: 45,
    },
    heightMd: {
      type: Number | String,
      default: null,
    },
    heightSm: {
      type: Number | String,
      default: null,
    },
    width: {
      type: String | Number,
      default: 'fit-content',
    },
    widthMd: {
      type: String | Number,
      default: null,
    },
    widthSm: {
      type: String | Number,
      default: null,
    },
    type: {
      type: String,
      default: 'primary',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    to: {
      type: String | Object,
      default: null,
    },
    capitalize: {
      type: Boolean,
      default: true,
    },
    gap: {
      type: String | Number,
      default: '10',
    },
  },
  computed: {
    typeComponent() {
      let typeComponent = 'button';
      if (this.to) {
        typeComponent = /^https?:\/\//i.test(this.to) ? 'a' : 'router-link';
      }
      return typeComponent;
    },
    cssVars() {
      const width = !isNaN(this.width) ? `${this.width}px` : this.width;
      const widthMd = this.widthMd
        ? !isNaN(this.widthMd)
          ? `${this.widthMd}px`
          : this.widthMd
        : width;
      const widthSm = this.widthSm
        ? !isNaN(this.widthSm)
          ? `${this.widthSm}px`
          : this.widthSm
        : widthMd;
      const height = !isNaN(this.height) ? `${this.height}px` : this.height;
      const heightMd = this.heightMd
        ? !isNaN(this.heightMd)
          ? `${this.heightMd}px`
          : this.heightMd
        : height;
      const heightSm = this.heightSm
        ? !isNaN(this.heightSm)
          ? `${this.heightSm}px`
          : this.heightSm
        : heightMd;
      const gap = !isNaN(this.gap) ? `${this.gap}px` : this.gap;

      const padding = this.width === '100%' ? '0' : '0 20px';

      return {
        '--width': width,
        '--widthMd': widthMd,
        '--widthSm': widthSm,
        '--height': height,
        '--heightMd': heightMd,
        '--heightSm': heightSm,
        '--gap': gap,
        '--padding': padding,
      };
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.unzip-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: var(--gap, 10px);
  border-radius: 5px;
  padding: var(--padding);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  white-space: nowrap;

  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  max-height: var(--height);
  min-height: var(--height);

  @include md-down {
    width: var(--widthMd);
    height: var(--heightMd);
    max-height: var(--heightMd);
    min-height: var(--heightMd);
  }

  @include sm-down {
    width: var(--widthSm);
    max-width: var(--widthSm);
    height: var(--heightSm);
    max-height: var(--heightSm);
    min-height: var(--heightSm);
  }

  &.no-events {
    pointer-events: none;
  }

  &.primary {
    color: var(--color-grey-280);
    border-color: transparent;
    &.outlined {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      background: transparent !important;
    }
  }

  &.primary:hover {
    background: var(--color-primary-hover) !important;
    &.outlined {
      color: var(--color-grey-280);
      background: var(--color-grey-900) !important;
      border-color: transparent !important;
    }
  }

  &.secondary {
    color: var(--color-grey-280);
    background: var(--color-grey-900) !important;
    border-color: transparent;
    &.outlined {
      color: var(--color-grey-900);
      background: transparent !important;
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-grey-900) !important;
    }
  }

  &.secondary:hover {
    background: var(--color-primary-hover) !important;
    &.outlined {
      color: var(--color-grey-280);
      background: var(--color-grey-900) !important;
      border-color: transparent;
    }
  }

  &.gray {
    color: var(--color-grey-600);
    background: var(--color-grey-400) !important;
    border-color: transparent;
    &.outlined {
      color: var(--color-grey-600);
      background: transparent !important;
      border: 1px solid var(--color-grey-600);
    }
  }

  &.gray:hover {
    background: var(--color-primary-hover) !important;
    &.outlined {
      color: var(--color-grey-280);
      background: var(--color-grey-900) !important;
      border-color: transparent;
    }
  }

  &.text {
    background: none;
    color: var(--color-primary);
    padding: 0;
    height: auto;
    min-height: auto;
    margin-top: 5px;
  }

  &.tab {
    background: var(--color-grey-280);
    border-radius: 10px;
    margin: 0 5px;
    padding: 10px 15px;
    color: var(--color-grey-500) !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-transform: capitalize;
    letter-spacing: 0;
    min-width: 0;
    &:hover {
      background: #343434;
    }
  }

  &:disabled,
  &:disabled:hover {
    background: var(--color-grey-400) !important;
    color: var(--color-grey-600);
    border-color: transparent;

    &.outlined {
      color: var(--color-grey-600);
      background: transparent !important;
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-grey-600) !important;
    }
  }

  &.capitalize {
    text-transform: capitalize;
  }

  .v-icon {
    color: inherit !important;
  }
}
</style>
