<template>
  <v-select
    :value="selectedOptions"
    :items="displayedItems"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    :loading="loading"
    :loading-text="'Loading...'"
    :rules="rules"
    :label="label"
    :placeholder="placeholder"
    :menu-props="{ offsetY: true, ripple: false }"
    :ripple="false"
    :clearable="clearable && !multiple"
    @change="handleChange"
    :disabled="isDisabled"
    :hide-details="hideDetails"
    :class="{ required }"
    :autocomplete="false"
    :item-disabled="() => isDisabled"
    class="unzipvr-input input-multiselect"
    data-testid="elem_u_003179"
    ref="select"
  >
    <template v-slot:selection="{ item, index }">
      <div
        v-if="!multiple || (index === 0 && selectedOptions.length)"
        class="displayed-value"
        data-testid="elem_u_003180"
      >
        <span
          v-if="multiple && isAllSelected"
          data-testid="elem_u_003181"
        >
          All
        </span>
        <template v-else>
          <span
            v-if="multiple && selectedOptions.length > 1"
            class="count-items-selected"
            data-testid="elem_u_003183"
          >
            {{ selectedOptions.length }} selected
          </span>
          <span v-else>
            {{ getItemText(item) }}
          </span>
        </template>
      </div>
    </template>

    <template v-slot:prepend-item>
      <v-list-item
        v-if="items.length && (autocomplete || multiple)"
        class="custom-item"
        data-testid="elem_u_003184"
        :disabled="isDisabled || loading"
      >
        <v-list-item-content
          class="pa-0"
          data-testid="elem_u_003185"
        >
          <div
            v-if="autocomplete"
            :class="{ multiple: multiple }"
            class="search-container"
            data-testid="elem_u_003186"
          >
            <input
              v-model="searchValue"
              class="autocomplete-input"
              type="text"
              data-testid="elem_u_003187"
            />
          </div>
          <div
            v-if="multiple"
            @click="toggleSelectAll"
            data-testid="elem_u_003188"
          >
            <v-list-item-title data-testid="elem_u_003189">
              <v-icon
                :color="selectedOptions?.length ? '#F4DB64' : '#E9E9E9'"
                class="all-icon"
                data-testid="elem_u_003190"
              >
                {{ allSelectedIcon }}
              </v-icon>
              <span data-testid="elem_u_003191">All</span>
            </v-list-item-title>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template
      v-if="multiple"
      #item="{ item, attrs }"
    >
      <div class="input-multiselect__item">
        <div class="v-list-item__title">
          <span class="v-icon notranslate all-icon theme--dark">
            <v-checkbox
              class="input-multiselect__checkbox"
              v-model="attrs.inputValue"
              dense
              hide-details
            ></v-checkbox>
          </span>
          <span class="v-list-item__text">{{ getItemText(item) }}</span>
        </div>
        <div
          class="input-multiselect__button-only"
          @click.stop="onClickOnly(item)"
        >
          Only
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { WIDGET_EMPTY_VALUE } from '@/components/common/Provider/EditFormBlock';

export default {
  name: 'UnzipSelect',
  props: {
    value: {
      type: String | Number,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    sorting: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedOptions: [],
      searchValue: '',
      isDisabled: this.disabled,
      stopInitEvent: this.multiple,
    };
  },

  computed: {
    isAllSelected() {
      return this.selectedOptions?.length === this.items.length;
    },

    isIndeterminateState() {
      return this.selectedOptions?.length > 0 && !this.isAllSelected;
    },

    displayedItems() {
      let displayedItems = [];

      if (this.sorting) {
        displayedItems = this.items.sort((a, b) =>
          this.getItemText(a).localeCompare(this.getItemText(b)),
        );
      } else {
        displayedItems = this.items;
      }

      if (this.searchValue && this.autocomplete) {
        let reg = new RegExp(this.searchValue, 'i');

        const itemsFiltered = displayedItems.filter((item) => reg.test(this.getItemText(item)));

        displayedItems = itemsFiltered;
      }

      return displayedItems;
    },

    allSelectedIcon() {
      if (this.isAllSelected) {
        return '$checkboxOn';
      } else if (this.isIndeterminateState) {
        return '$checkboxIndeterminate';
      } else return '$checkboxOff';
    },
  },

  watch: {
    displayedItems: function () {
      if (this.multiple && !this.value) {
        this.selectAll();
      }
    },

    value: function (val) {
      if (this.multiple && !val) {
        this.selectedOptions = this.items.map((item) => this.getItemValue(item));
      } else if (val !== WIDGET_EMPTY_VALUE && val !== this.selectedOptions) {
        this.handleChange(val);
      } else if (val === WIDGET_EMPTY_VALUE) {
        this.$refs.select.form.reset();
      }
    },

    disabled: function (value) {
      this.isDisabled = value;
    },
  },

  methods: {
    onClickOnly(selectedItem) {
      this.handleChange([this.getItemValue(selectedItem)]);
      this.onInput([this.getItemValue(selectedItem)]);
    },

    handleChange(values) {
      this.selectedOptions = values;
      const outputValue = Array.isArray(values) ? this.getOutputValue() : values;

      this.$nextTick(() => {
        if ((!this.multiple || outputValue !== null) && !this.stopInitEvent) {
          this.$emit('input', outputValue);
          this.$emit('change', outputValue);
        }

        this.stopInitEvent = false;
      });
    },

    toggleSelectAll() {
      if (!this.isAllSelected) {
        this.selectAll();
      } else {
        this.selectedOptions = [];
      }

      this.handleChange(this.selectedOptions);
    },

    selectAll() {
      this.selectedOptions = this.items.map((item) => this.getItemValue(item));
    },

    getOutputValue() {
      if (!this.selectedOptions?.length) {
        return null;
      } else if (this.isAllSelected) {
        return '';
      } else {
        return this.selectedOptions;
      }
    },

    getItemText(item) {
      return item[this.itemText] ?? item;
    },

    getItemValue(item) {
      return item[this.itemValue] ?? item;
    },
  },

  async mounted() {
    this.selectedOptions = this.value;

    await this.$nextTick();

    this.$refs.select.resetValidation();

    this.stopInitEvent = false;
  },
};
</script>

<style lang="scss">
.input-multiselect {
  width: 100%;

  .displayed-value {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;

    .count-items-selected {
      margin-left: auto;
      color: var(--color-primary);
    }
  }
  .v-select__slot {
    .error--text {
      color: var(--color-grey-600) !important;
    }
  }
}

.v-input--is-disabled {
  .displayed-value {
    color: var(--color-grey-400) !important;
  }
}

.v-select-list {
  padding: 0;
  .custom-item {
    cursor: pointer;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.v-simple-checkbox {
  line-height: 1;
}

.input-multiselect__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .input-multiselect__button-only {
      visibility: visible;
    }
  }
}

.input-multiselect__button-only {
  color: var(--color-grey-600);
  font-size: 14px;
  line-height: 16px;
  padding: 2px 5px;

  visibility: hidden;

  &:hover {
    color: var(--color-grey-900);
  }
}

.input-multiselect__checkbox {
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding: 0;
}
</style>
