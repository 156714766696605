import axios from 'axios';

export default async (query) => {
  let response = null;

  try {
    if (query.lac) {
      response = await axios.post(`/v1/authentication/login-as-user`, { code: query.lac });
    }

    if (query.lbc) {
      response = await axios.post(`/v1/authentication/login-by-code`, { code: query.lbc });
    }
  } catch (error) {
    return error;
  }

  return response;
};
